import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Pagination } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { searchable } from "../../utils/index.js";
import { UserContext } from "../Application/UserContext.js";
import { Table } from "../common/Table/index.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { submissionTypesQuery } from "./query.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { handleGraphQLError, handleMutation } from "../../utils/errorHandling.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";
import { OPERATIONAL_REPORTS } from "../../constants/index.js";
import { MODIFY_SUBMISSION_TYPE_REPORT_TEMPLATE } from "../../constants/mutations.js";

export const SubmissionTypesTable = ({
    reportTemplate,
    contractTypeId
}) => {
    const [modified, setModified] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortOn, setSortOn] = useState("specifier");
    const [sortBy, setSortBy] = useState("ascend");
    const [nameSearch, setNameSearch] = useState("");
    const [specifierSearch, setSpecifierSearch] = useState("");
    const [reportTemplateId, setReportTemplateId] = useState(reportTemplate?.id);
    const [addSubmissionTypesModelVis, setAddSubmissionTypesModelVis] = useState(false);
    const { preloadedData } = usePreloadedData();
    const [modifySubmissionTypeReportTemplate] = useMutation(MODIFY_SUBMISSION_TYPE_REPORT_TEMPLATE);
    const { refetchPreloadedData } = usePreloadedData();

    const {
        userPermEditSubmissionType,
        userIsSuperUser
    } = useContext(UserContext);

    useEffect(() => {
        if (reportTemplate && reportTemplate.id) {
            setReportTemplateId(reportTemplate.id);
        }
    }, [reportTemplate]);

    const queryVariables = {
        offset: (page - 1) * pageSize,
        limit: pageSize,
        nameSearch,
        specifierSearch,
        reportTemplateId: reportTemplateId,
        sortOn,
        sortBy
    };

    const { loading, error, data, refetch } = useQuery(submissionTypesQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });
    const getSubmissionTypesWithNoReportTemplate = () => {

        const category = preloadedData?.categories.find(({ name }) => name === OPERATIONAL_REPORTS);

        const submissionTypesList = preloadedData?.submissionTypes.filter(({
            categoryId,
            reportTemplateId,
            deleted,
            contract_type_id
        }) => {
            return categoryId === category.id && reportTemplateId === null && deleted === false  && contract_type_id === contractTypeId ;
        }).map(({ specifier, name, id }) => {
            return {
                text: `${specifier} - ${name}`,
                value: id,
                key: id
            };
        });
        return submissionTypesList;
    };

    if (error) {
        return handleGraphQLError(error);
    }

    const modifyReportTemplate = async({deleted, submissionTypesIds}) => {
        await handleMutation(
            modifySubmissionTypeReportTemplate({
                variables: {
                    reportTemplateId,
                    submissionTypesIds: submissionTypesIds,
                    deleted: deleted
                }
            })
        );
        refetch();
        refetchPreloadedData();
    };

    const addSubmissionTypesModel = (
        <div>
            <Modal
                title="Submission Types"
                open={addSubmissionTypesModelVis}
                okText="Save"
                closable={false}
                maskClosable={false}
                okButtonProps={{
                    disabled: (typeof modified.submissionTypesIds === "undefined")
                }}
                onOk={async () => {
                    modifyReportTemplate({deleted: false, submissionTypesIds: modified.submissionTypesIds});
                    setModified({});
                    setAddSubmissionTypesModelVis(false);
                }}
  
                onCancel={() => {
                    setModified({});
                    setAddSubmissionTypesModelVis(false);
                }}
                destroyOnClose={true}
            >
                <div>
                    <div style={{ fontSize: "14px" }}>
                        <p>
                            <span>Select All Submission Types that will use this Report Template version: </span>
                        </p>

                        <SelectableDetail
                            title="Submission type"
                            multiple={true}
                            value={modified?.submissionTypesIds ?? []}
                            onValueUpdated={(value) => {
                                setModified({
                                    ...modified,
                                    submissionTypesIds: typeof value === "string" ? [value] : [...value]
                                });
                            }}
                            
                            options={getSubmissionTypesWithNoReportTemplate()}
                        />
                        
                    </div>
                </div>
            </Modal>
        </div>
    );

    const submissionTypesColumns = [
        searchable({
            title: "ID",
            dataIndex: "specifier",
            key: "specifier",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: (specifier, { id }) => {
                return (
                    <PCDULink 
                        to={`/submission-types/${id}`}
                    >
                        {specifier}
                    </PCDULink>
                );
            },
            handleSearch: (textToSearch) => {
                setSpecifierSearch(textToSearch);
                setPage(1);
            },
            searchedColumn: () => { },
            searchedText: specifierSearch
        }),
        searchable({
            title: "Name",
            dataIndex: "name",
            handleSearch: (textToSearch) => {
                setNameSearch(textToSearch);
                setPage(1);
            },
            searchedText: nameSearch,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"]
        })
    ];

    if (userPermEditSubmissionType || userIsSuperUser) {
        const actionColumn = {
            title: "Actions",
            key: "actions",
            width: "10%",
            render: ({ id }) => {
                return (
                    <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: "24px" }}
                        onClick={() => {
                            modifyReportTemplate({
                                deleted: true,
                                submissionTypesIds: [id]
                            });
                        }}
                    >
                        Remove
                    </Button>
                );
            }
        };
        submissionTypesColumns.splice(2, 0, actionColumn);
    }

    return (
        <div style={{ marginTop: "60px" }}>

            <div>
                <div style={{ display: "flex", marginBottom: "10px", marginTop: "10px"  }}>
                    <div><span style={{
                        fontSize: "16px",
                        fontWeight: "600"
                    }}>Submission Types</span></div>

                    {(userPermEditSubmissionType || userIsSuperUser) && (<div style={{ marginLeft: "16px" }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                setAddSubmissionTypesModelVis(true);
                            }}
                        >ADD</Button>
                    </div>
                    )}

                </div>
                
                <div style={{ marginTop: "24px" }}>
                    {loading && typeof data === "undefined" &&
                        <LoadingContent />
                    }
                    <Table
                        id="submissionTypesTable"
                        columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, submissionTypesColumns)}
                        rowKey="id"
                        dataSource={data?.submissionTypesList?.submissionTypes}
                        pagination={false}
                        onChange={(_pagination, _filters, { field, order }) => {
                            setSortOn(field);
                            setSortBy(order);
                        }}
                    >
                    </Table>
                    <Pagination
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "20px 0 50px 0"
                        }}
                        showSizeChanger
                        onChange={(targetPage, pageSize) => {
                            setPage(targetPage);
                            setPageSize(pageSize);
                        }}
                        current={page}
                        pageSize={pageSize}
                        pageSizeOptions={["10", "25", "50", "100"]}
                        total={data?.submissionTypesList?.count}
                    />
                    {addSubmissionTypesModel}
                </div>
            
            </div>
        </div>
    );
};